import set from 'lodash/set'
import omitBy from 'lodash/omitBy'
import type { FiltersEnum } from '@/types'

export const useCreateFilters = (filters: FiltersEnum) => {
  const route = useRoute()

  const filtersObj: Partial<FiltersEnum> = {}

  Object.entries(filters).forEach(([key, value]) => {
    let queryValue = route.query[key] as string | number[] | string[]
    if (Array.isArray(queryValue)) {
      queryValue = queryValue.map((v) => (+v ? Number(v) : v)) as number[]
    } else if (queryValue && Array.isArray(value)) {
      queryValue = +queryValue ? [Number(queryValue)] : [queryValue]
    }
    set(filtersObj, key, queryValue || value)
  })
  // Add properties from route.query that are not in filters
  Object.entries(route.query).forEach(([key, value]) => {
    if (!(key in filters)) {
      set(filtersObj, key, value)
    }
  })

  return filtersObj as FiltersEnum
}

export const useParseFilters = (
  filters: any,
  paginationData?: any,
  excludeKeys: string[] = [],
  replaceRoute = true
) => {
  const router = useRouter()
  // Remove empty filters
  const params = omitBy(
    toRaw(filters),
    (f, k) => f === undefined || f === null || f === '' || (Array.isArray(f) && !f.length) || excludeKeys.includes(k)
  )

  let query = params
  // Check pagination
  if (paginationData) {
    params.page = paginationData.current_page
    params.limit = paginationData.per_page
    query = { ...query, page: params.page, limit: params.limit }
  }
  if (replaceRoute) {
    router.replace({ query })
  }
  return params
}

export const useCountFilters = (filters: Object, excludeKeys: string[] = []) => {
  const mandatoryExcludeKeys = ['orderBy', 'sortedBy', 'page', 'limit', ...excludeKeys]
  let count = 0
  Object.entries(filters)
    .filter(([k]) => !mandatoryExcludeKeys.includes(k))
    .forEach(([_, value]) => {
      if (Array.isArray(value)) {
        if (value.length) count++
      } else if (value !== undefined && value !== null && value !== '') {
        count++
      }
    })
  return count
}
