import { H3Error } from 'h3'
import type { ApiResponse } from '~/types'

type FetchType = typeof $fetch

type FetchParameters = Parameters<FetchType>

export const useAPIFetch = async <T = ApiResponse>(path: FetchParameters[0], options?: FetchParameters[1]) => {
  const { $api } = useNuxtApp()

  const data = ref<T>()

  const error = ref<H3Error | undefined>(undefined)

  try {
    const response = await $api<T>(path, options)

    data.value = response
  } catch (err: any) {
    error.value = err
  }

  return {
    data: data as Ref<T>,
    error,
  }
}
