export const useGetWAChatMessages = async (chatId: string, older?: boolean, date?: string, limit: number = 20) => {
  let params
  if (date) {
    params = older ? { older_than_date: date } : { younger_than_date: date }
  }
  const { data, error } = await useAPIFetch(`/wa/chats/${chatId}/messages`, {
    params: { limit, page: 1, ...params },
  })
  if (error.value) throw createError(error.value)
  return data.value as any
}

export const useGetWALeadChats = async (leadId: number) => {
  const data = await useAPIFetch(`/wa/leads/${leadId}/chats`)
  return data as any
}

export const useWAMarkLeadChatAsRead = async (leadId: number) => {
  const { data, error } = await useAPIFetch(`/wa/leads/${leadId}/notifications/read`, {
    method: 'PATCH',
  })
  if (error.value) throw createError(error.value)
  return data as any
}

export const useGetUserWANotifications = async () => {
  const { data, error } = await useAPIFetch(`/wa/user/notifications/unread`)
  if (error.value) throw createError(error.value)
  return data.value as any
}

export const useGetWAMessageHistory = async (messageId: string) => {
  const { data, error } = await useAPIFetch(`/wa/messages/${messageId}/history`)
  if (error.value) throw createError(error.value)
  return data.value as any
}

export const useGetWASearchMessages = async (leadId: number, query: string) => {
  const { data, error } = await useAPIFetch(`/search/leads/${leadId}/wa_messages?limit=100&search_word=${query}`)
  if (error.value) throw createError(error.value)
  return data.value as any
}
