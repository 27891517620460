import { defineStore } from 'pinia'
import { TOURS_IDS } from './../constants'
import { useAuthStore } from './auth'
import type {
  AnalyticsFilters,
  LeadsFilters,
  MappingsFilters,
  RotationsFilters,
  SnackBanner,
  SnackTypes,
  UsersFilters,
  UserPermissionsFilters,
  ActivitiesFilters,
  NotificationsFilters,
  FiltersEnum,
  TablesEnum,
  RotationsSettingsFilters,
  DynamicComponent,
  DealsFilters,
  CallLogsFilters,
  AssignLogsFilters,
  DataEnrichmentFilters,
  DynamicActionsMenu,
  TableAction,
  LocalsFilters,
  PropertyTypes,
  Filters,
  Toast,
} from '@/types'
import type { ReleaseFilters } from '~/types/releases'
import type { PropertiesExclusiveFilters } from '~/types/properties'

type UiStore = {
  tableFilters: {
    allLeads: LeadsFilters | undefined
    sales: LeadsFilters | undefined
    salesQualifiedWithoutActions: LeadsFilters | undefined
    salesQualified: LeadsFilters | undefined
    pool: LeadsFilters | undefined
    archive: LeadsFilters | undefined
    queue: LeadsFilters | undefined
    callQueue: LeadsFilters | undefined
    calls: AnalyticsFilters | undefined
    campaigns: AnalyticsFilters | undefined
    customRotations: RotationsFilters | undefined
    defaultRotations: RotationsFilters | undefined
    rotationsSettings: RotationsSettingsFilters | undefined
    mappings: MappingsFilters | undefined
    users: UsersFilters | undefined
    archivedUsers: UsersFilters | undefined
    marketers: UsersFilters | undefined
    userPermissions: UserPermissionsFilters | undefined
    activities: ActivitiesFilters | undefined
    locals: LocalsFilters | undefined
    notifications: NotificationsFilters | undefined
    templates: Filters | undefined
    deals: DealsFilters | undefined
    dealsArchive: DealsFilters | undefined
    callLogs: CallLogsFilters | undefined
    dataEnrichment: DataEnrichmentFilters | undefined
    assignLogs: AssignLogsFilters | undefined
    properties: PropertyTypes.Filters | undefined
    releases: ReleaseFilters | undefined
    propertiesExclusivesUnits: PropertiesExclusiveFilters | undefined
  }
  tableColumns: {
    sales?: string[]
    pool?: string[]
    archive?: string[]
  }
  salesPipelineGridView: boolean
  activitiesCalendarView: boolean
  viewCalendarAsManager: boolean
  snackBanner: SnackBanner
  hasNewNotifications: boolean
  dynamicPopup: DynamicComponent
  dynamicSidePanel: DynamicComponent
  dynamicMobilePanel: DynamicComponent
  dynamicTour: DynamicComponent
  dynamicActionBar: DynamicComponent
  dynamicActionsMenu: DynamicActionsMenu
  leadPhoneHide: boolean
  needUpdateQueue: boolean
  needUpdateCallQueue: boolean
  leadMovedToQueue: boolean
  countLeadInQueue: number
  tableItemsPerPage: number
  toasts: Toast[]
  pipelineDataExporting: boolean
  archiveDataExporting: boolean
  dealDataExporting: boolean
  archiveDealDataExporting: boolean
}

const initDynamicComponent = () => ({
  value: false,
  component: undefined,
  data: {},
  events: {},
})

const initActionsMenu = () => ({
  value: false,
  actions: [],
})

export const useUiStore = defineStore('ui', {
  state: (): UiStore => ({
    tableFilters: {
      allLeads: undefined,
      sales: undefined,
      salesQualifiedWithoutActions: undefined,
      salesQualified: undefined,
      pool: undefined,
      archive: undefined,
      queue: undefined,
      callQueue: undefined,
      calls: undefined,
      campaigns: undefined,
      customRotations: undefined,
      defaultRotations: undefined,
      rotationsSettings: undefined,
      mappings: undefined,
      users: undefined,
      archivedUsers: undefined,
      marketers: undefined,
      userPermissions: undefined,
      activities: undefined,
      locals: undefined,
      notifications: undefined,
      templates: undefined,
      deals: undefined,
      dealsArchive: undefined,
      callLogs: undefined,
      dataEnrichment: undefined,
      assignLogs: undefined,
      properties: undefined,
      releases: undefined,
      propertiesExclusivesUnits: undefined,
    },
    tableColumns: {
      sales: undefined,
      pool: undefined,
      archive: undefined,
    },
    salesPipelineGridView: true,
    activitiesCalendarView: false,
    viewCalendarAsManager: false,
    snackBanner: {
      value: false,
      type: 'success',
      text: '',
    },
    hasNewNotifications: false,
    dynamicPopup: initDynamicComponent(),
    dynamicSidePanel: initDynamicComponent(),
    dynamicMobilePanel: initDynamicComponent(),
    dynamicTour: initDynamicComponent(),
    dynamicActionBar: initDynamicComponent(),
    dynamicActionsMenu: initActionsMenu(),
    leadPhoneHide: false,
    needUpdateQueue: false,
    needUpdateCallQueue: false,
    leadMovedToQueue: false,
    countLeadInQueue: 0,
    tableItemsPerPage: 20,
    toasts: [],
    pipelineDataExporting: false,
    archiveDataExporting: false,
    dealDataExporting: false,
    archiveDealDataExporting: false,
  }),
  actions: {
    toggleSalesPipelineGridView() {
      this.salesPipelineGridView = !this.salesPipelineGridView
    },
    setPipelineDataExporting() {
      this.pipelineDataExporting = true
    },
    unsetPipelineDataExporting() {
      this.pipelineDataExporting = false
    },
    setArchiveDataExporting() {
      this.archiveDataExporting = true
    },
    unsetArchiveDataExporting() {
      this.archiveDataExporting = false
    },
    setDealDataExporting() {
      this.dealDataExporting = true
    },
    unsetDealDataExporting() {
      this.dealDataExporting = false
    },
    setArchiveDealDataExporting() {
      this.archiveDealDataExporting = true
    },
    unsetArchiveDealDataExporting() {
      this.archiveDealDataExporting = false
    },
    toggleActivitiesCalendarView() {
      this.activitiesCalendarView = !this.activitiesCalendarView
    },
    showSnackBanner(
      text: string = '',
      type: SnackTypes = 'success',
      action?: Function,
      actionButtonText?: string,
      secondaryAction?: Function,
      secondaryActionButtonText?: string
    ) {
      this.snackBanner = {
        value: true,
        type,
        text: text || (type === 'success' ? 'Success' : 'Something went wrong'),
        action,
        actionButtonText,
        secondaryAction,
        secondaryActionButtonText,
      }
    },
    showPopup(component: Component, data: Object = {}, events: Object = {}) {
      this.dynamicPopup = {
        value: true,
        component: markRaw(component),
        data,
        events,
      }
    },
    cleanPopup() {
      this.dynamicPopup = initDynamicComponent()
    },
    showSidePanel(component: Component, data: Object = {}, events: Object = {}) {
      this.dynamicSidePanel = {
        value: true,
        component: markRaw(component),
        data,
        events,
      }
    },
    cleanSidePanel() {
      this.dynamicSidePanel = initDynamicComponent()
    },
    showMobilePanel(component: Component, data: Object = {}, events: Object = {}) {
      this.dynamicMobilePanel = {
        value: true,
        component: markRaw(component),
        data,
        events,
      }
    },
    cleanMobilePanel() {
      this.dynamicMobilePanel = initDynamicComponent()
    },
    showTour({
      component,
      data = {},
      events = {},
      id,
      checkCompleted = true,
    }: {
      component: Component
      data?: Object
      events?: Object
      id: TOURS_IDS
      checkCompleted?: boolean
    }) {
      // TODO: improve overall architecture
      const { getUser } = useAuthStore()

      if (getUser.tours?.includes(id) && checkCompleted) {
        // Tour has already been completed, no need to mount component
        return
      }

      if (this.dynamicTour.value) {
        // Tour is already mounted, no need to mount component
        return
      }

      this.dynamicTour = {
        value: true,
        component: markRaw(component),
        data,
        events,
      }
    },
    cleanTour() {
      this.dynamicTour = initDynamicComponent()
    },
    showActionBar(component: Component, data: Object = {}, events: Object = {}) {
      this.dynamicActionBar = {
        value: true,
        component: markRaw(component),
        data,
        events,
      }
    },
    cleanActionBar() {
      this.dynamicActionBar = initDynamicComponent()
    },
    resetSnackBanner() {
      this.snackBanner = {
        value: false,
        type: 'success',
        text: '',
      }
    },
    setTableFilters(table: TablesEnum, filters: FiltersEnum) {
      // @ts-ignore
      this.tableFilters[table] = filters
    },
    setTableColumns(table: TablesEnum, columns: string[]) {
      this.tableColumns[table] = columns
    },
    setHasNewNotifications(value: boolean) {
      this.hasNewNotifications = value
    },
    toggleViewCalendarAsAgent() {
      this.viewCalendarAsManager = !this.viewCalendarAsManager
    },
    setLeadPhoneHide(value: boolean) {
      this.leadPhoneHide = value
    },
    setNeedUpdateQueue(value: boolean) {
      this.needUpdateQueue = value
      setTimeout(() => {
        this.needUpdateQueue = false
      }, 1000)
    },
    setNeedUpdateCallQueue(value: boolean) {
      this.needUpdateCallQueue = value
      setTimeout(() => {
        this.needUpdateCallQueue = false
      }, 1000)
    },
    setLeadMovedToQueue(value: boolean) {
      this.leadMovedToQueue = value
      setTimeout(() => {
        this.leadMovedToQueue = false
      }, 1000)
    },
    setCountLeadInQueue(value: number) {
      this.countLeadInQueue = value
    },
    showDynamicActionsMenu(name: string, actions: TableAction[], x: number, y: number) {
      if (this.dynamicActionsMenu?.value) {
        this.cleanDynamicActionsMenu()
        setTimeout(() => {
          this.dynamicActionsMenu = {
            value: true,
            actions,
            name,
            x,
            y,
          }
        })
      } else {
        this.dynamicActionsMenu = {
          value: true,
          actions,
          name,
          x,
          y,
        }
      }
    },
    cleanDynamicActionsMenu() {
      this.dynamicActionsMenu = initActionsMenu()
    },
    setTableItemsPerPage(value: number) {
      this.tableItemsPerPage = value
    },
    showToast(type: string, props: Record<string, any>) {
      const id = Date.now()
      this.toasts.unshift({ id, type, props })

      setTimeout(() => {
        this.removeToast(id)
      }, 5000)
    },
    removeToast(id: number) {
      this.toasts = this.toasts.filter((toast: Toast) => toast.id !== id)
    },
    clearTableFilters() {
      Object.entries(this.tableFilters).forEach(([key]) => {
        this.tableFilters[key as TablesEnum] = undefined
      })
    },
  },
  getters: {
    getSnackBanner: (state) => state.snackBanner,
    getSalesPipelineGridView: (state) => state.salesPipelineGridView,
    getPipelineDataExporting: (state) => state.pipelineDataExporting,
    getArchiveDataExporting: (state) => state.archiveDataExporting,
    getDealDataExporting: (state) => state.dealDataExporting,
    getArchiveDealDataExporting: (state) => state.archiveDealDataExporting,
    getActivitiesCalendarView: (state) => state.activitiesCalendarView,
    getAllLeadsTableFilters: (state) => state.tableFilters.allLeads,
    getSalesTableFilters: (state) => state.tableFilters.sales,
    getSalesQualifiedWithoutActionsTableFilters: (state) => state.tableFilters.salesQualifiedWithoutActions,
    getSalesQualifiedTableFilters: (state) => state.tableFilters.salesQualified,
    getPoolTableFilters: (state) => state.tableFilters.pool,
    getArchiveTableFilters: (state) => state.tableFilters.archive,
    getQueueTableFilters: (state) => state.tableFilters.queue,
    getCallQueueTableFilters: (state) => state.tableFilters.callQueue,
    getCallsTableFilters: (state) => state.tableFilters.calls,
    getCampaignsTableFilters: (state) => state.tableFilters.campaigns,
    getCustomRotationsTableFilters: (state) => state.tableFilters.customRotations,
    getDefaultRotationsTableFilters: (state) => state.tableFilters.defaultRotations,
    getRotationsSettingsTableFilters: (state) => state.tableFilters.rotationsSettings,
    getMappingsTableFilters: (state) => state.tableFilters.mappings,
    getUsersTableFilters: (state) => state.tableFilters.users,
    getArchivedUsersTableFilters: (state) => state.tableFilters.archivedUsers,
    getMarketersTableFilters: (state) => state.tableFilters.marketers,
    getUserPermissionsTableFilters: (state) => state.tableFilters.userPermissions,
    getActivitiesTableFilters: (state) => state.tableFilters.activities,
    getLocalsTableFilters: (state) => state.tableFilters.locals,
    getNotificationsFilters: (state) => state.tableFilters.notifications,
    getTemplateTableFilters: (state) => state.tableFilters.templates,
    getDealsFilters: (state) => state.tableFilters.deals,
    getDealsArchiveFilters: (state) => state.tableFilters.dealsArchive,
    getCallLogsFilters: (state) => state.tableFilters.callLogs,
    getAssignLogsFilters: (state) => state.tableFilters.assignLogs,
    getHasNewNotifications: (state) => state.hasNewNotifications,
    getViewCalendarAsManager: (state) => state.viewCalendarAsManager,
    getDynamicPopup: (state) => state.dynamicPopup,
    getDynamicSidePanel: (state) => state.dynamicSidePanel,
    getDynamicMobilePanel: (state) => state.dynamicMobilePanel,
    getDynamicTour: (state) => state.dynamicTour,
    getDynamicActionBar: (state) => state.dynamicActionBar,
    getLeadPhoneHide: (state) => state.leadPhoneHide,
    getDataEnrichmentTableFilters: (state) => state.tableFilters.dataEnrichment,
    getNeedUpdateQueue: (state) => state.needUpdateQueue,
    getNeedUpdateCallQueue: (state) => state.needUpdateCallQueue,
    getLeadMovedToQueue: (state) => state.leadMovedToQueue,
    getCountLeadInQueue: (state) => state.countLeadInQueue,
    getDynamicActionsMenu: (state) => state.dynamicActionsMenu,
    getTableItemsPerPage: (state) => state.tableItemsPerPage,
    getPropertiesFilters: (state) => state.tableFilters.properties,
    getReleasesFilters: (state) => state.tableFilters.releases,
    getToasts: (state) => state.toasts,
    getPropertiesExclusiveFilters: (state) => state.tableFilters.propertiesExclusivesUnits,
    getArchiveTableColumns: (state) => state.tableColumns.archive,
    getPoolTableColumns: (state) => state.tableColumns.pool,
    getSalesTableColumns: (state) => state.tableColumns.sales,
  },
  persist: {
    storage: persistedState.localStorage,
    paths: ['salesPipelineGridView', 'activitiesCalendarView', 'tableFilters', 'tableItemsPerPage', 'tableColumns'],
  },
})
