import { defineStore } from 'pinia'
import merge from 'lodash/merge'
import { useAuthStore } from '@/store/auth'
import type { WhatsappChat, WhatsappMessage } from '@/types'

type WhatsappStore = {
  disconnected: boolean
  loading: boolean
  hasIntegration: boolean
  isWAUser: boolean
  validPhone: boolean
  whatsappCurrentNumber: string
  qrCode: string
  chats: WhatsappChat[]
  leadChats: WhatsappChat[]
  chat: WhatsappChat | null
  messages: WhatsappMessage[]
  pagination: any | null
  scrollToEnd: boolean
  hasNewerMessage: boolean
}

export const useWhatsappStore = defineStore('whatsapp', {
  state: (): WhatsappStore => ({
    disconnected: false,
    loading: true,
    hasIntegration: false,
    isWAUser: true,
    validPhone: true,
    whatsappCurrentNumber: '',
    qrCode: '',
    chats: [],
    leadChats: [],
    chat: null,
    messages: [],
    pagination: null,
    scrollToEnd: false,
    hasNewerMessage: false,
  }),
  actions: {
    setDisconnected(value: boolean = true) {
      this.disconnected = value
    },
    setLoading(value: boolean = false) {
      this.loading = value
    },
    setIntegration(value: boolean) {
      this.hasIntegration = value
    },
    setWAUser(value: boolean) {
      this.isWAUser = value
    },
    async setValidPhone(value: any) {
      this.validPhone = value.isTheSame
      this.whatsappCurrentNumber = value.whatsappCurrentNumber
      if (!value.isTheSame) {
        await triggerWALogout()
        setTimeout(() => {
          this.validPhone = true
        }, 500)
      }
    },
    setQrCode(value: string) {
      this.qrCode = value
    },
    async setChat(leadId: number) {
      const { data } = await useGetWALeadChats(leadId)
      if (!data.value.data.length) {
        this.resetChat()
        return
      }
      this.chat = data.value.data[0]
      await this.setMessages(this.chat!.whatsappId)
    },
    async setLeadChats(leadId: number) {
      const authStore = useAuthStore()
      const { data } = await useGetWALeadChats(leadId)
      if (!data.value.data.length) {
        this.resetChat()
        return
      }
      this.leadChats = data.value.data

      const myChat = this.leadChats.find((chat: WhatsappChat) => chat.crm_user_contact.userId === authStore.getUser.id)
      if (myChat && this.getHasIntegration) {
        myChat.crm_user_contact.userName = 'My chat'
        this.chat = myChat
        await this.setMessages(this.chat!.whatsappId)
      } else {
        if (myChat) {
          this.leadChats = this.leadChats.filter(
            (chat: WhatsappChat) => chat.crm_user_contact.userId !== authStore.getUser.id
          )
        }
        this.chat = {
          whatsappId: 'my-chat',
          crm_user_contact: {
            userName: 'My chat',
            userId: authStore.getUser.id,
          },
        }
        this.leadChats.unshift({
          whatsappId: 'my-chat',
          crm_user_contact: {
            userName: 'My chat',
            userId: authStore.getUser.id,
          },
        })
      }
    },
    changeChat(chatId: string) {
      this.chat = this.leadChats.find((chat: WhatsappChat) => chat.whatsappId === chatId)
    },
    resetChat() {
      this.chat = null
      this.messages = []
      this.leadChats = []
      this.hasNewerMessage = false
    },
    async setMessages(chatId: string, older: boolean = true, date?: string, more: boolean = false, limit?: number) {
      const { data, pagination } = await useGetWAChatMessages(chatId, older, date, limit)
      if (!more) {
        if (this.hasNewerMessage) this.hasNewerMessage = false
        this.messages = data.reverse()
      } else if (older) {
        this.messages = [...data.reverse(), ...this.messages]
      } else {
        this.hasNewerMessage = !!data.length
        this.messages = [...this.messages, ...data]
      }

      this.pagination = pagination
      return data
    },
    setNewChat(value: WhatsappChat) {
      this.chat = value
      if (this.leadChats.length) {
        this.leadChats = this.leadChats.filter((chat: WhatsappChat) => chat.whatsappId !== 'my-chat')
        this.leadChats.unshift(value)
      }
    },
    setNewMessage(value: WhatsappMessage) {
      if (value.chatId !== this.chat?.whatsappId || this.hasNewerMessage) return
      this.messages.push(value)
      if (value.isFromMe) {
        this.scrollToEnd = true
        setTimeout(() => {
          this.scrollToEnd = false
        }, 100)
      }
    },
    updateChat(value: { unreadCount: number; message: WhatsappMessage }) {
      if (value.message.chatId !== this.chat?.whatsappId) return
      this.chat.unreadCount = value.unreadCount
      this.updateMessage(value.message)
    },
    updateMessage(message: WhatsappMessage) {
      if (message.chatId !== this.chat?.whatsappId) return
      const updatedMessageIndex = this.messages.findIndex((m: WhatsappMessage) => m.id === message.id)
      if (updatedMessageIndex) {
        this.messages[updatedMessageIndex] = merge(this.messages[updatedMessageIndex], message)
      }
    },
    updateUnreadCount(value: any) {
      if (!this.chat || value.chatWhatsappId !== this.chat?.whatsappId) return
      this.chat.unreadCount = value.unreadCount
    },
    setChats(value: any[]) {},
    deleteMessage(id: string) {
      const updatedMessageIndex = this.messages.findIndex((m: WhatsappMessage) => m.id === id)
      this.messages.splice(updatedMessageIndex, 1)
    },
  },
  getters: {
    getDisconnected: (state) => state.disconnected,
    getLoading: (state) => state.loading,
    getHasIntegration: (state) => state.hasIntegration,
    getIsWAUser: (state) => state.isWAUser,
    getValidPhone: (state) => state.validPhone,
    getWhatsappCurrentNumber: (state) => state.whatsappCurrentNumber,
    getQrCode: (state) => state.qrCode,
    getChats: (state) => state.chats,
    getLeadChats: (state) => state.leadChats,
    getChat: (state) => state.chat,
    getMessages: (state) => state.messages,
    getPagination: (state) => state.pagination,
  },
})
