<template>
  <div class="flex cursor-pointer flex-col gap-4" @click="getActivity()">
    <UiLoader v-if="loading" />
    <div class="flex flex-row items-center gap-2">
      <UiInputCheckbox
        v-if="!readOnlyNoDetails"
        :model-value="isDone"
        :name="vModel.title"
        round
        border-color-class="border-black-30"
        :read-only="
          isDone ||
          (vModel.user?.id !== authStore.getUser.id &&
            !useHasPermissions([PERMISSIONS.CLOSE_ACTIVITIES_ON_BEHALF_OF_THE_USER]))
        "
        @click.stop
        @update:model-value="updateActivityStatus"
      />
      <transition name="fade">
        <UiTagPrimary
          v-if="vModel.status?.code === CALENDAR_ACTIVITY_STATUSES.OVERDUE"
          id="overdue"
          right-icon=""
          class="!cursor-default"
          background-color-class="bg-secondary-20"
        >
          <span class="text-subhead-3 text-error-100"> Overdue </span>
        </UiTagPrimary>
      </transition>
      <UiTagPrimary
        id="type"
        right-icon=""
        class="!cursor-default"
        :style="{
          backgroundColor: CALENDAR_ACTIVITY_TYPES_COLORS_MAP.get(vModel.type?.code)?.background,
        }"
      >
        <span class="text-subhead-3 text-black-100">
          {{ vModel.type.name }}
        </span>
      </UiTagPrimary>
      <span class="text-subhead-2">{{ vModel.title }}</span>
    </div>
    <div v-if="isDone">
      Activity planned on {{ format(new Date(vModel.schedule_from), 'MMM dd, yyyy') }} was marked as
      {{ vModel.status?.name }} by <span class="text-subhead-1"> {{ authorName }}</span
      >.
    </div>
    <div class="flex flex-col gap-4">
      <div v-if="!isDone">Activity was created on {{ format(new Date(vModel.created_at), 'MMM dd, yyyy') }}.</div>
      <div>
        <span class="text-subhead-1">Scheduled for</span>
        <span class="ml-2 w-fit rounded-sm bg-secondary-20 px-1">{{ formattedDate }}</span>
      </div>
      <div v-if="vModel.participants.length && !readOnlyNoDetails && vModel.participants !== HIDDEN">
        {{ vModel.participants.length }} guests: {{ vModel.participants.toString() }}.
      </div>
      <div v-if="vModel.comment">
        <div class="text-caption-2 text-black-40">Description:</div>
        <div>{{ vModel.comment }}</div>
      </div>
    </div>
    <div v-if="vModel.meet_url && !readOnlyNoDetails">
      <div class="text-caption-2 mb-1 text-black-40">You can connect by using this link:</div>
      <UiButtonBase
        id="join_google_meeting"
        type="secondary"
        @click="
          navigateTo(vModel.meet_url, {
            external: true,
            open: {
              target: '_blank',
            },
          })
        "
      >
        <UiIcon name="google-meet" />
        Join Google Meet meeting
      </UiButtonBase>
    </div>
  </div>
</template>

<script setup lang="ts">
import { format } from 'date-fns'
import { useUiStore } from '@/store/ui'
import { POPUPS, SIDE_PANELS } from '@/components/dynamic/maps'
import type { LibraryItem, TimelineItem, Activity } from '@/types'
import { useAuthStore } from '~/store/auth'
import {
  CALENDAR_ACTIVITY_TYPES,
  CALENDAR_ACTIVITY_STATUSES,
  CALENDAR_ACTIVITY_TYPES_COLORS_MAP,
  CUSTOM_EVENTS,
  PERMISSIONS,
  HIDDEN,
} from '@/constants'

const emits = defineEmits(['update:modelValue'])

const authStore = useAuthStore()
const uiStore = useUiStore()

const readOnlyNoDetails = computed(() => authStore.getPermissions.includes(PERMISSIONS.SEE_TIMELINE_NO_DETAILS))

type Props = {
  modelValue: TimelineItem & Activity
}
const props = defineProps<Props>()

const vModel = useVModel(props, 'modelValue', emits)
const activity = ref()
const loading = ref(false)

const lead = inject<Ref<boolean>>('lead')

const authorName = vModel.value.closed_by?.id
  ? vModel.value.closed_by?.id === authStore.getUser.id
    ? 'You'
    : vModel.value.closed_by?.name
  : vModel.value.user?.id === authStore.getUser.id
  ? 'You'
  : vModel.value.user?.name

const eventStatuses = ref<LibraryItem[]>([])
onNuxtReady(async () => {
  eventStatuses.value = await useCalendarActivityStatuses()
})

const isDone = computed(() => vModel.value.status.code === CALENDAR_ACTIVITY_STATUSES.DONE)

const formattedDate = computed(() => {
  if (vModel.value.type?.code === CALENDAR_ACTIVITY_TYPES.ALL_DAY)
    return format(new Date(vModel.value.due_date), 'MMM dd, yyyy') + ', all day'

  // from and to time
  return (
    format(new Date(vModel.value.schedule_from), 'MMM dd, yyyy') +
    ', from ' +
    format(new Date(vModel.value.schedule_from), 'HH:mm') +
    ' to ' +
    format(new Date(vModel.value.schedule_to), 'HH:mm')
  )
})

const updateActivityStatus = async () => {
  if (vModel.value.type?.code === CALENDAR_ACTIVITY_TYPES.MEETING && !vModel.value.meeting_outcome_resolution_id) {
    uiStore.showPopup(
      POPUPS.MEETING_OUTCOME,
      { activity: { ...vModel.value, start: vModel.value.schedule_from, end: vModel.value.schedule_to, lead } },
      { input: () => refreshHistory() }
    )
    return
  }
  const statusCode = CALENDAR_ACTIVITY_STATUSES.DONE

  const status = eventStatuses.value.find((s) => s.code === statusCode)
  if (!status) return

  vModel.value = { ...vModel.value, status }
  try {
    await useUpdateCalendarActivityStatus(Number(vModel.value.id), status.id)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const getActivity = async () => {
  loading.value = true
  if (!activity.value) {
    try {
      activity.value = await useGetCalendarActivity(vModel.value.id)
    } catch (error: any) {
      uiStore.showSnackBanner(error.message, 'error')
    }
  }
  loading.value = false
  setTimeout(() => {
    uiStore.showSidePanel(
      SIDE_PANELS.ACTIVITY_PREVIEW,
      { activity },
      {
        edit: () => showEditEventSidePanel(),
        delete: () => showDeleteActivityPopup(),
        'update:modelValue': () => refreshHistory(),
        'update:status': (status: LibraryItem) => (activity.value.status = status),
      }
    )
  }, 200)
}

const showEditEventSidePanel = () => {
  if (vModel.value.status?.code === CALENDAR_ACTIVITY_STATUSES.DONE) return
  uiStore.cleanSidePanel()
  setTimeout(() => {
    uiStore.showSidePanel(
      SIDE_PANELS.ACTIVITY_CREATE_EDIT,
      { activityToEdit: activity },
      {
        edited: (updatedActivity: Activity) => {
          activity.value = updatedActivity
          refreshHistory()
        },
      }
    )
  }, 200)
}

const showDeleteActivityPopup = () => {
  uiStore.showPopup(
    POPUPS.ACTIVITY_DELETE,
    { activity },
    { cancel: () => getActivity(), input: () => refreshHistory() }
  )
}

const refreshHistory = () => {
  document.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.REFRESH_LEAD_HISTORY))
}
</script>

<style scoped></style>
