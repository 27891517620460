import type { LeadTagUiProperties } from '@/types'
import { TAGS } from '@/constants'

const TAGS_MAP = new Map<string, LeadTagUiProperties>([
  [
    TAGS.HIGH_QUALITY_LEAD,
    {
      text: 'High Quality',
      icon: 'status-done',
      tooltip: 'High Quality - this lead is qualified by SDR team.',
      class: 'bg-secondary-20 text-black-100',
    },
  ],
  [
    TAGS.IN_UAE,
    {
      text: 'In UAE',
      icon: 'in-uae-lead',
      tooltip: 'In UAE. Lead is located in UAE.',
      class: 'bg-secondary-20 text-black-100',
    },
  ],
  [
    TAGS.FRESH_LEAD,
    {
      text: 'Fresh',
      icon: 'fresh-lead',
      tooltip: 'Fresh lead. Work with this lead was not started.',
      class: 'bg-additional-2-20 text-additional-2-120',
    },
  ],
  [
    TAGS.UNFROZEN_LEAD,
    {
      text: 'Unfrozen',
      icon: 'unfrozen-lead',
      tooltip: 'This lead was unfreezed from “Future buyer”. Please review lead history in timeline.',
      class: 'bg-primary-10 text-primary-80',
    },
  ],
  [
    TAGS.HOT_LEAD,
    {
      text: 'Hot',
      icon: 'hot-lead',
      tooltip:
        'Hot lead. Created as a result of merging two or more leads or creating multiple requests with the same contact information.',
      class: 'bg-additional-1-50 text-error-100',
    },
  ],
  [
    TAGS.SHADOW_ORIGINAL_LEAD,
    {
      text: 'Shadow original',
      icon: 'shadow-original-lead',
      tooltip:
        'Shadow-original. The original lead, based on which a copy was created and then assigned to another agent.',
      class: 'text-black-100 bg-black-10',
    },
  ],
  [
    TAGS.SHADOW_COPY_LEAD,
    {
      text: 'Shadow copy',
      icon: 'shadow-copy-lead',
      tooltip:
        'Shadow-copy. Lead that was created as an exact duplicate of another lead and then assigned to a new agent as fresh.',
      class: 'text-black-100 bg-black-10',
    },
  ],
  [
    TAGS.FAILED,
    {
      text: 'Failed lead',
      icon: 'failed-lead',
      tooltip: 'Failed lead. The lead contains a failed phone number.',
      class: 'bg-additional-3-10 text-additional-3-100',
    },
  ],
  [
    TAGS.REFERRAL,
    {
      text: 'Referral',
      icon: 'referral',
      tooltip: 'Referral - lead from Partners Portal.',
      class: 'text-black-100 bg-black-10',
    },
  ],
  [
    TAGS.ENRICHED,
    {
      text: 'Enriched',
      icon: 'enriched',
      tooltip: 'Enriched. Lead data has been enriched or fixed through the application of the enrichment rule.',
      class: 'bg-secondary-20 text-black-100',
    },
  ],
  [
    TAGS.FBYB,
    {
      text: 'FBYB',
      icon: 'airplane-filled',
      tooltip: 'Fly Before You Buy',
      class: 'bg-secondary-20 text-black-100',
    },
  ],
  [
    TAGS.DIC,
    {
      text: 'DIC',
      icon: 'dic-lead',
      tooltip: "DIC. Dubai Insider's Club member.",
      class: 'bg-secondary-20 text-black-100',
    },
  ],
])

export default (code: string): LeadTagUiProperties | undefined => TAGS_MAP.get(code)
