import mitt from 'mitt'

type ApplicationEvents = {
  'tour:start': () => void
  'tour:setCallLogsMock': () => void
  'tour:clearCallLogsMock': () => void
  'tableFilters:loaded': (id: string) => void
  'tour:globalSearch': () => void
  'tour:setGlobalSearchMock': () => void
  'tour:clearGlobalSearchMock': () => void
  'tour:activityCreationType': () => void
  'tour:activityMeetingCreation': () => void
  'tour:setSDRQueueMock': () => void
  'tour:clearSDRQueueMock': () => void
  'tour:SDRQueue:createActivity': () => void
  'tour:newlyAdded': () => void
  'table:salesAll:mock': () => void
  'lead:reassign': () => void
  'popup:mounted': () => void
  'tour:welcomeAboard': () => void
  'tour:propertiesOffers': () => void
  'tour:pinNote': () => void
  'tour:pinNotePushPin': () => void
  'tour:unpinNote': () => void
  'tour:clearPinNoteMock': () => void
  'tour:quickFilters': () => void
}

const emitter = mitt<ApplicationEvents>()

type EmitFunction = (type: keyof ApplicationEvents, ...payload: any[]) => void

export const useMittEvent: EmitFunction = emitter.emit

export const useMittListen = emitter.on

export const useMittRemove: EmitFunction = emitter.off
