<template>
  <Transition name="fade" mode="out-in">
    <div v-if="loading && !isSDRTourActive" :class="['w-full', { 'max-w-[183px]': !isMobile }]">
      <UiLoader />
    </div>
    <UiInputMenu
      v-else
      id="change-stage"
      :model-value="modelValue"
      :items="stageItems"
      name="stage"
      placeholder="Find status"
      search
      :width="isMobile ? undefined : 183"
      :class="isMobile ? 'w-full' : 'w-fit'"
      @update:model-value="updateStage"
    >
      <template #activator="{ onClick, isOpen }">
        <UiTagPrimary
          id="stage"
          :is-menu-open="isOpen"
          left-icon="operations-forward"
          :full-width="isMobile"
          :class="{
            '!cursor-default': readonly || isClosed || isPoolLead,
            '!h-9 !justify-center': isMobile,
            '!bg-black-05 !text-black-40 [&>svg]:text-black-40': isClosed,
          }"
          :right-icon="!readonly && !isPoolLead && !isClosed ? undefined : ''"
          :disabled="modelValue === closedStage?.id"
          @click="!!readonly || isPoolLead || isClosed ? null : onClick()"
        >
          <UiTooltip name="state-tooltip">
            <template #activator>
              <h5 class="text-subhead-3">
                {{ currentTag }}
              </h5>
            </template>
            <template v-if="isUnverifiedLead" #content>
              <div>Fill correct number to change status to Verified</div>
            </template>
          </UiTooltip>
        </UiTagPrimary>
      </template>
    </UiInputMenu>
  </Transition>
</template>

<script setup lang="ts">
import { POPUPS, SIDE_PANELS } from '@/components/dynamic/maps'
import { STAGES, PIPELINES, POOL_STAGES, CUSTOM_EVENTS } from '@/constants'
import type { InputItem, Lead, Stage } from '@/types'
import { useUiStore } from '@/store/ui'

const uiStore = useUiStore()

const emits = defineEmits(['update:modelValue', 'stages:loaded'])

type Props = {
  modelValue: number
  lead: Lead
  readonly?: boolean
  isMobile?: boolean
}

const props = defineProps<Props>()

const loading = ref(true)
const closedStage = ref()
const stageItems = ref<InputItem[]>([])

const isPoolLead = computed(() => Object.values(POOL_STAGES).includes(props.lead.stage?.code as string))
const isUnverifiedLead = computed(() => props.lead.stage?.code === POOL_STAGES.UNVERIFIED)
const isSDRTourActive = inject<Ref<boolean>>('isSDRTourActive')

watch(
  () => isPoolLead.value,
  async () => {
    await Promise.all([getSalesPipeline(), getPoolPipeline()])
  }
)

onNuxtReady(async () => {
  await Promise.all([getSalesPipeline(), getPoolPipeline()])
  loading.value = false
  nextTick(() => {
    emits('stages:loaded')
  })
})

const getSalesPipeline = async () => {
  try {
    const pipeline = await useGetPipelineByCode(PIPELINES.SALES)
    if (!isPoolLead.value) stageItems.value = pipeline.stages.map((s: Stage) => ({ value: s.id, text: s.name, ...s }))
    closedStage.value = pipeline.stages.find((s) => s.code === STAGES.CLOSED)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const getPoolPipeline = async () => {
  try {
    const pool = await useGetPipelineByCode(PIPELINES.POOL)
    if (isPoolLead.value) stageItems.value = pool.stages?.map((s: Stage) => ({ value: s.id, text: s.name, ...s }))
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const isClosed = inject<Ref<boolean>>('isClosed')
const isClosedLost = inject<Ref<boolean>>('isClosedLost')
const isClosedWon = inject<Ref<boolean>>('isClosedWon')

const currentTag = computed(() => {
  const stage = stageItems.value.find(({ value }) => value === props.modelValue)

  if (isClosedLost?.value) {
    return 'Closed Lost'
  }

  if (isClosedWon?.value) {
    return 'Closed Won'
  }

  if (props.lead.pipeline?.code === PIPELINES.QUEUE) {
    return 'New'
  }

  return stage?.text
})

const updateStage = (stageId: number) => {
  const newStage = stageItems.value.find((i) => i.value === stageId)

  const popup = useGetStageTransitionPopup(props.lead.stage?.code as string, newStage?.code as string)
  // If there's a popup for the stage transition, show it
  if (popup) {
    uiStore.showPopup(
      popup,
      { stage: newStage, lead: props.lead },
      {
        input: () => refreshLead(),
        create: () => {
          uiStore.showSidePanel(SIDE_PANELS.PIPELINE_LEAD_CREATE, undefined, {
            created: () => refreshLead(),
            duplicate: (lead: Lead) => {
              uiStore.showPopup(
                POPUPS.PIPELINE_LEAD_DUPLICATE,
                {
                  lead,
                },
                {
                  created: () => refreshLead(),
                }
              )
            },
            suggest: (lead: Lead, duplicateId: string) => {
              uiStore.showPopup(
                POPUPS.PIPELINE_LEAD_DUPLICATE_SUGGEST,
                { lead, duplicateId },
                {
                  created: () => refreshLead(),
                }
              )
            },
          })
        },
        lost: () => {
          uiStore.showPopup(
            POPUPS.PIPELINE_LEAD_CLOSE_LOST,
            { stage: newStage, lead: props.lead },
            {
              input: () => refreshLead(),
            }
          )
        },
        won: () => {
          uiStore.showSidePanel(
            SIDE_PANELS.PIPELINE_DEAL_CREATE,
            { stage: newStage, lead: props.lead },
            {
              input: () => refreshLead(),
            }
          )
        },
      }
    )
  } else {
    emits('update:modelValue', stageId)
  }
}

const refreshLead = () => {
  document.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.REFRESH_LEAD_HISTORY))
  document.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.REFRESH_LEAD_DATA))
}
</script>

<style scoped></style>
