import { defineStore } from 'pinia'
import * as JsSIP from 'jssip'
import * as Sentry from '@sentry/vue'
import { useAuthStore } from '@/store/auth'

export const useIOStore = defineStore('io', {
  state: () => ({
    userAgent: shallowRef<JsSIP.UA | null>(null),
    hasActivatedPhoneCall: null,
  }),
  actions: {
    async getUserAgent() {
      if (this.userAgent) return this.userAgent

      return await this.init()
    },
    async init() {
      if (this.userAgent) return null

      try {
        const { login, password } = await useGetCallsCredentials()

        const uri = `sip:${login}@pbx.thrivestate.app`
        const socket = new JsSIP.WebSocketInterface('wss://pbx.thrivestate.app:7443/ws')

        const configuration = {
          sockets: [socket],
          uri,
          password,
          register_expires: 120,
          session_timers: true,
          use_preloaded_route: false,
        }

        this.userAgent = markRaw(new JsSIP.UA(configuration))

        this.userAgent?.start()

        this.userAgent?.on('disconnected', (data) => {
          Sentry.captureEvent({ message: 'Pbx socket disconnected', level: 'error', extra: { data } })
        })
      } catch (error) {
        Sentry.captureException(error)
      }

      return this.userAgent as JsSIP.UA
    },
    async activateCall() {
      const authStore = useAuthStore()
      if (!authStore.getUser.is_voice_call_enabled || !authStore.getIsActiveForOutboundCall) return

      if (this.userAgent && this.userAgent?.status !== 1) {
        this.userAgent?.start()
      } else {
        await this.init()
      }
    },
    disableCall() {
      const authStore = useAuthStore()
      if (authStore.getUser.is_voice_call_enabled && authStore.getIsActiveForOutboundCall) return

      if (this.userAgent && this.userAgent?.status === 1) {
        this.userAgent?.stop()
      }
    },
    setHasActivatedPhoneCall(value: any) {
      this.hasActivatedPhoneCall = value
    },
  },
  getters: {
    getUA: (state) => state.userAgent,
    getHasActivatedPhoneCall: (state) => state.hasActivatedPhoneCall,
  },
})
