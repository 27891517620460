<template>
  <div class="relative h-full overflow-y-auto">
    <Transition name="fade">
      <div
        v-if="showTopOverflowBorder"
        class="absolute -top-1 h-1 w-full border-b border-solid border-black-10 bg-transparent"
        :class="{ 'shadow-4': showTopOverflowBorder }"
      />
    </Transition>

    <div ref="source" class="flex h-full flex-col gap-4 overflow-y-auto p-8 2xl:p-12 2xl:pt-8" @scroll="checkScroll">
      <div
        :class="{ hidden: modelValue.rotation === null && (authStore.getIsSalesManager || authStore.getIsAgent) }"
        class="text-body-2 flex flex-col gap-4"
      >
        <h5>Leads rotation reasons</h5>
        <div>
          <div class="text-black-60">Mapping rule</div>
          <div>
            {{
              modelValue.rotation?.mappings
                .map((m) => m.name)
                .flat()
                .toString()
                .replace(',', ', ') || '-'
            }}
          </div>
        </div>
        <div class="text-body-2">
          <div class="text-black-60">Parameters</div>
          <div>
            {{ modelValue.rotation?.parameter_name || '-' }}
          </div>
        </div>
        <div class="text-body-2">
          <div class="text-black-60">Rotation box</div>
          <div
            id="goToRotation"
            :class="{ 'text-subhead-4 cursor-pointer underline': modelValue.rotation }"
            @click="
              modelValue.rotation
                ? navigateTo(`/rotations/${modelValue.rotation?.type}?query=${modelValue.rotation?.name}`, {
                    open: {
                      target: '_blank',
                    },
                  })
                : null
            "
          >
            {{
              modelValue.rotation
                ? `${modelValue.rotation?.type === ROTATION_TYPES.DEFAULT ? 'Default' : 'Custom'} box: ${modelValue.rotation?.name}`
                : '-'
            }}
          </div>
        </div>
      </div>
      <div class="text-body-2">
        <h5 class="pb-3">Digital lead</h5>
        <div>
          {{ modelValue.is_digital ? 'Yes' : 'No' }}
        </div>
      </div>
      <div class="text-body-2">
        <h5 class="pb-3">Source</h5>
        <div>
          {{ sourceName }}
        </div>
        <div>
          {{ modelValue.campaign?.utm_source }}
        </div>
      </div>
      <div class="text-body-2">
        <h5 class="pb-3">Campaign name</h5>
        {{ modelValue.campaign?.utm_campaign || '-' }}
      </div>
      <div class="text-body-2 relative">
        <h5 class="pb-3">Term</h5>
        <span
          ref="termsArea"
          :class="{ 'h-[45px]': isOverHeight }"
          class="block h-full overflow-hidden transition-all"
          >{{ modelValue.campaign?.utm_term || '-' }}</span
        >
        <span v-if="isOverHeight" class="text-primary-100 underline" @click="isOverHeight = !isOverHeight">more</span>
      </div>
      <div class="text-body-2">
        <h5 class="pb-3">Content</h5>
        {{ modelValue.campaign?.utm_content || '-' }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Lead } from '@/types'
import { useAuthStore } from '~/store/auth'
import { ROTATION_TYPES } from '~/constants'

type Props = {
  modelValue: Lead
}

const props = defineProps<Props>()

const authStore = useAuthStore()

let contentOverflows = ref(false)
const showTopOverflowBorder = ref(false)
const source = ref<HTMLElement>()
const isOverHeight = ref(false)
const termsArea = ref<HTMLElement>()

const PARTNER_PORTAL_SOURCE_CODE = 'partner_portal'

const sourceName = computed(() => {
  if (props.modelValue.source.code === PARTNER_PORTAL_SOURCE_CODE && props.modelValue.partner?.id) {
    return `${props.modelValue.source?.name} - ID: ${props.modelValue.partner.id}`
  }

  return props.modelValue.source?.name
})

onMounted(() => {
  contentOverflows = computed(() => Number(source?.value?.scrollHeight) > Number(source?.value?.offsetHeight))
  if (termsArea.value?.clientHeight !== undefined && termsArea.value?.clientHeight > 45) {
    isOverHeight.value = true
  }
})

const checkScroll = () => {
  if (contentOverflows.value) showTopOverflowBorder.value = Number(source.value?.scrollTop) > 0
}
</script>

<style scoped></style>
