<template>
  <div>
    <div
      :id="`${name}_activator`"
      ref="activator"
      class="h-full"
      :class="[activatorFullWidth ? 'w-full' : 'w-fit', activatorClasses]"
      @mouseenter="handleMouseEnter"
      @mouseleave="isHovered = false"
    >
      <slot name="activator" @click="isHovered = false"></slot>
    </div>

    <Teleport to="body">
      <div
        v-if="$slots.content && !disabled"
        :id="`${name}_tooltip`"
        ref="content"
        :style="{
          ...floatingStyles,
          maxWidth: props.width ? `${props.width}px` : 'auto',
        }"
        class="text-body-2 invisible fixed z-50 inline-block max-w-[300px] whitespace-normal rounded-lg bg-black-100 px-3 py-2 font-medium text-white opacity-0 shadow-sm transition-opacity duration-300"
        :class="[
          tooltipClasses,
          {
            '!visible !opacity-100': isHovered,
          },
        ]"
      >
        <div :id="`${name}_arrow`" ref="floatingArrow" :style="arrowStyles" class="absolute rotate-45 bg-black-100" />
        <slot name="content"></slot>
      </div>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { flip, useFloating, arrow, offset, type Placement, type Strategy } from '@floating-ui/vue'

type Props = {
  name: string
  tooltipClasses?: string
  width?: number
  positionRight?: boolean
  positionLeft?: boolean
  positionTop?: boolean
  disabled?: boolean
  activatorFullWidth?: boolean
  strategy?: Strategy
  fallbackPlacements?: Placement[]
  activatorClasses?: string
}

const props = withDefaults(defineProps<Props>(), {
  tooltipClasses: '',
  width: undefined,
  strategy: 'fixed',
  fallbackPlacements: () => [
    'bottom',
    'bottom-start',
    'bottom-end',
    'top',
    'top-start',
    'top-end',
    'left',
    'right',
    'right-start',
    'right-end',
    'left-start',
    'left-end',
  ],
  activatorClasses: '',
})

const placementFormatted = computed<Placement>(() => {
  if (props.positionTop) return 'top'
  if (props.positionRight) return 'right'
  if (props.positionLeft) return 'left'

  return 'bottom'
})

const arrowSize = 6
const arrowSizePx = `${arrowSize}px`
const arrowSizeHalf = arrowSize / 2

const activator = ref<HTMLDivElement | null>(null)
const content = ref<HTMLDivElement | null>(null)
const floatingArrow = ref<HTMLDivElement | null>(null)

const isHovered = ref(false)

const handleMouseEnter = () => {
  isHovered.value = true
  update()
}

const { floatingStyles, middlewareData, placement, update } = useFloating(activator, content, {
  placement: placementFormatted.value,
  strategy: props.strategy,
  middleware: [
    flip({
      fallbackPlacements: props.fallbackPlacements,
    }),
    arrow({ element: floatingArrow, padding: 10 }),
    offset(4.3),
  ],
  open: isHovered,
})

const staticSide: any = computed(
  () =>
    ({
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    }[placement.value.split('-')[0]])
)

const arrowStyles = computed(() => ({
  width: arrowSizePx,
  height: arrowSizePx,
  left: middlewareData.value.arrow?.x ? `${middlewareData.value.arrow?.x}px` : '',
  top: middlewareData.value.arrow?.y ? `${middlewareData.value.arrow?.y}px` : '',
  [staticSide.value]: `-${arrowSizeHalf}px`,
}))
</script>

<style scoped></style>
