import * as Sentry from '@sentry/vue'
import { useAuthStore } from '@/store/auth'
import type { User, TwoFA, QRCode, ApiResponse } from '@/types'

type LoginData = {
  token: string
  user: User
}

export const useLogin = async (email: string, password: string) => {
  const authStore = useAuthStore()
  const { data, error } = await useAPIFetch('auth/login', {
    method: 'post',
    body: {
      email,
      password,
    },
  })
  if (error.value) {
    throw createError(error.value)
  }
  const loginData = (data.value as ApiResponse).data as LoginData & TwoFA
  if (loginData?.is_2fa_enabled) {
    authStore.setUserId(loginData.user_id)
    navigateTo('/login/twoFA')
  } else {
    authStore.setToken(loginData.token)
    authStore.setUser(loginData.user)
    return loginData.user
  }
}

export const useGoogleLogin = () => {
  const config = useRuntimeConfig()
  return navigateTo(`${config.public.API_URL}auth/oauth/login?redirect=${config.public.APP_URL}`, {
    external: true,
  })
}

export const useLogout = (makeApiCall: boolean = true) => {
  const authStore = useAuthStore()
  if (makeApiCall) {
    useDisconnectFromWebsocket()
    useAPIFetch('/users/logout', {
      method: 'delete',
    })
  }
  Sentry.setUser(null)
  setTimeout(() => {
    authStore.clearUser()
    return navigateTo('/login')
  })
}

export const useForgotPassword = async (email: string) => {
  const { data, error } = await useAPIFetch('/forgot-password', {
    method: 'post',
    body: {
      email,
    },
  })

  if (error.value) {
    throw createError(error.value)
  }

  return data
}

export const useVerifyResetCode = async (email: string, token: string) => {
  const { data, error } = await useAPIFetch('/users/verify-token', {
    method: 'post',
    body: {
      email,
      token,
    },
  })
  if (error.value) throw createError(error.value)
  return data
}
export const useResetPassword = async (token: string, email: string, password: string) => {
  const { data, error } = await useAPIFetch('/reset-password', {
    method: 'post',
    body: {
      token,
      email,
      password,
    },
  })

  if (error.value) {
    throw createError(error.value)
  }

  return data
}

export const useRegisterVerifyEmail = async (email: string) => {
  const { data, error } = await useAPIFetch('/users/verify-email', {
    method: 'post',
    body: {
      email,
    },
  })
  if (error.value) {
    throw createError(error.value)
  }

  return data
}
export const useRegisterVerifyCode = async (email: string, code: string) => {
  const { data, error } = await useAPIFetch('/users/confirm-email', {
    method: 'post',
    body: {
      email,
      code,
    },
  })
  if (error.value) throw createError(error.value)
  return data
}

export const useRegisterPassword = async (code: string, email: string, password: string) => {
  const authStore = useAuthStore()
  const { data, error } = await useAPIFetch<LoginData>('/users/create', {
    method: 'post',
    body: {
      code,
      email,
      password,
    },
  })
  if (error.value) {
    throw createError(error.value)
  }
  const loginData = data.value

  authStore.setToken(loginData.token)
  authStore.setUser(loginData.user)
}

export const useUpdatePassword = async (oldPassword: string, password: string) => {
  const { data, error } = await useAPIFetch('/users/edit-password', {
    method: 'put',
    body: {
      old_password: oldPassword,
      password,
    },
  })
  if (error.value) throw createError(error.value)
  return data.value
}

export const useUser = () => {
  const authStore = useAuthStore()
  return authStore.getIsLoggedIn ? authStore.getUser : null
}

export const useQRCode = async () => {
  const { data, error } = await useAPIFetch('/auth/2fa', {
    method: 'get',
  })
  if (error.value) throw createError(error.value)
  return (data.value as ApiResponse).data as QRCode
}

export const useEnabledTwoFA = async (secret: string, code: string, action: number) => {
  const { data, error } = await useAPIFetch('auth/2fa', {
    method: 'post',
    body: {
      secret,
      code,
      action,
    },
  })
  if (error.value) {
    throw createError(error.value)
  } else {
    const authStore = useAuthStore()
    authStore.setUserSecret(action ? secret : null)
    await useGetProfile()
  }
  return (data.value as ApiResponse).data as QRCode
}

export const useDisableTwoFA = async (userId: number) => {
  const { data, error } = await useAPIFetch(`auth/2fa/${userId}`, {
    method: 'PATCH',
  })
  if (error.value) throw createError(error.value)
  return (data.value as ApiResponse).data
}

export const useConfirmTwoFA = async (userId: number, code: string): Promise<User> => {
  const { data, error } = await useAPIFetch(`auth/2fa/${userId}/confirm`, {
    method: 'post',
    body: {
      code,
    },
  })
  if (error.value) {
    throw error.value.message
  } else {
    const loginData = (data.value as ApiResponse).data as LoginData & TwoFA
    const authStore = useAuthStore()
    authStore.setToken(loginData.token)
    authStore.setUser(loginData.user)
    return loginData.user
  }
}
