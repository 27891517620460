import debounce from 'lodash/debounce'
import omitBy from 'lodash/omitBy'
import { stringify } from 'qs'
import { useAuthStore } from '@/store/auth'
import { useLibrariesStore } from '@/store/libraries'
import type { ApiResponse, PaginationResponse, User } from '@/types'
import type { TOURS_IDS } from '~/constants'

// This request is too big, we need to cache it
// TODO: change format of parameters to object
export const useGetUsers = debounce(
  async (force?: boolean, withDeleted?: boolean) => {
    const librariesStore = useLibrariesStore()
    if (librariesStore.getUsers?.length && !force) {
      return librariesStore.getUsers
    } else {
      const { data, error } = await useAPIFetch(`/users`, {
        params: { with_deleted: withDeleted },
      })
      if (error.value) throw createError(error.value)
      const users = (data?.value?.data as User[]) || []
      librariesStore.setUsers(users)
      return librariesStore.getUsers
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useGetAllUsers = debounce(
  async (force?: boolean) => {
    const librariesStore = useLibrariesStore()
    if (librariesStore.getAllUsers?.length && !force) {
      return librariesStore.getAllUsers
    } else {
      const { data, error } = await useAPIFetch(`/users/available`)
      if (error.value) throw createError(error.value)
      const users = (data?.value?.data as User[]) || []
      librariesStore.setAllUsers(users)
      return librariesStore.getAllUsers
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useGetPaginatedUsers = async (params: Object) => {
  const query = omitBy(toRaw(params), (f) => f === null || f === undefined)
  const queryFormatted = stringify(query, { arrayFormat: 'comma', encode: false })

  const { data, error } = await useAPIFetch<PaginationResponse>(`/settings/users?${queryFormatted}`)
  if (error.value) throw createError(error.value)
  return data.value
}

export const useGetPaginatedMarketers = async (query: Object) => {
  const { data, error } = await useAPIFetch<PaginationResponse>(`/settings/users/marketers`, { query })
  if (error.value) throw createError(error.value)
  return data.value
}

export const useGetProfile = async () => {
  const authStore = useAuthStore()

  const { data, error } = await useAPIFetch<
    ApiResponse<{
      token: string
      user: User
    }>
  >('/users/view')

  if (error.value) throw createError(error.value)

  authStore.setUser(data.value?.data?.user)

  return data.value.data?.user
}

export const useUpdateProfile = async (body: object) => {
  const authStore = useAuthStore()

  const { data, error } = await useAPIFetch<{
    user: User
    message: string
  }>('/users/edit', { method: 'put', body })
  if (error.value) throw createError(error.value)
  authStore.setUser(data.value.user as User)

  return data.value
}

export const useUserHasRotations = async (userId: number) => {
  const { data, error } = await useAPIFetch(`/users/${userId}/has_rotations`)
  if (error.value) throw createError(error.value)
  return data.value
}

export const useUserHasLeads = async (userId: number) => {
  const { data, error } = await useAPIFetch(`/users/${userId}/active_leads`)
  if (error.value) throw createError(error.value)
  return data.value
}

export const useDeleteUser = async (userId: number) => {
  const { data, error } = await useAPIFetch(`/settings/users/${userId}`, { method: 'DELETE' })
  if (error.value) throw createError(error.value)
  return data.value
}

export const useGetUser = async (userId: number) => {
  const { data, error } = await useAPIFetch(`/settings/users/edit/${userId}`)
  if (error.value) throw createError(error.value)
  return data.value
}

export const useEditUser = async (userId: number, user: User) => {
  const { data, error } = await useAPIFetch(`/settings/users/edit/${userId}`, { method: 'PUT', body: user })
  if (error.value) throw createError(error.value)
  return data.value
}

export const useSetUserStatus = async (userId: number, statusId: number) => {
  const { data, error } = await useAPIFetch(`/users/${userId}/status`, {
    method: 'PATCH',
    body: { status_id: statusId },
  })
  if (error.value) throw createError(error.value)
  return data.value
}

export const useToggleAgentMode = async (userId: number) => {
  const { data, error } = await useAPIFetch(`/users/${userId}/toggle/as_agent`, {
    method: 'PATCH',
  })
  if (error.value) throw createError(error.value)
  return data.value
}

export const useGetUsersActivityTime = async (userId: number) => {
  const { data, error } = await useAPIFetch(`users/metrics/average-time/${userId}`)
  if (error.value) throw createError(error.value)
  return data.value as ApiResponse
}

export const useToggleShowingOnLanding = async (userId: number) => {
  const { data, error } = await useAPIFetch(`/users/${userId}/toggle/landing`, {
    method: 'PATCH',
  })
  if (error.value) throw createError(error.value)
  return data.value as ApiResponse
}

export const useUpdateTourStatus = async (tourId: TOURS_IDS) => {
  const { data, error } = await useAPIFetch(`/users/tours/${tourId}`, {
    method: 'POST',
    params: {
      tourId,
    },
  })
  if (error.value) throw createError(error.value)
  return data.value as ApiResponse
}

export const useToggleSdrQueue = async (userId: number) => {
  return await useAPIFetch(`/users/${userId}/toggle/sdr-queue-eligible`, {
    method: 'PATCH',
  })
}
